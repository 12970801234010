import React, { useState } from "react";
import { IoCheckmark } from "react-icons/io5";
import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import pink from "../assets/pink.png";
import Faqs from "../components/Faqs";

const Pricing = () => {
  return (
    <div>
      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:py-[100px] py-[80px] bg-[#F7FDFF]">
        <div className="max-w-[1480px] mx-auto">
          <h4 className="lg:text-[60px] text-[36px] font-[600] md:font-[700] leading-[44px] lg:leading-[72px] text-[#101828] text-center lg:w-[700px] mx-auto tracking-tight">
            Pricing plans that grow with your business
          </h4>
          <h4 className="text-[20px] font-[400] leaading-[30px] text-[#667085] mt-[24px] text-center font-ibm lg:w-[768px] mx-auto">
            Our flexible pricing models are built to adapt to your company's
            growth and evolving talent sourcing requirements.
          </h4>
          <div className="mt-[50px]">
            {/* <div className="flex justify-center  border-[1px] border-gray-200 rounded-[20px] w-fit mx-auto">
              <button className="text-white bg-[#101828] rounded-[20px] px-[16px] py-[6px] text-[15px] font-[500]">
                Monthly
              </button>
              <button className="text-[#667085] px-[16px] py-[6px] text-[15px] font-[400]">
                Annually
              </button>
            </div> */}

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-[24px] font-ibm md:w-[400px] xl:w-[1000px]  mx-auto mt-[100px]">
              <div className="bg-white border-[1px] border-[#F2F2F2] rounded-[24px] p-[24px]">
                <h4 className="text-[20px] font-[600] text-text leading-[30px]">
                  Essential
                </h4>

                <button
                  onClick={() =>
                    window.open("https://app.rivala.io/pricing?step=1")
                  }
                  className="py-[12px] px-[20px] rounded-[8px]  text-[16px] font-[500] text-white hover:bg-gray-800 w-full flex justify-center mt-[48px] bg-text"
                >
                  Try Essential
                </button>
                <div className="grid grid-cols-1 mt-[48px] gap-[8px]">
                  {/* <h4 className="text-text font-[700] text-[20px]">
                    All in Free +
                  </h4> */}
                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Offshore recruitment services
                    </h4>
                  </div>
                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Background checks
                    </h4>
                  </div>
                </div>
              </div>
              <div className="border-[#F2F2F2] bg-white border-[1px] rounded-[24px] p-[24px]">
                <h4 className="text-[20px] font-[600] text-text leading-[30px]">
                  Profesional
                </h4>

                <button
                  onClick={() =>
                    window.open("https://app.rivala.io/pricing?step=1")
                  }
                  className="py-[12px] px-[20px] rounded-[8px] border-[1px] border-text text-[16px] font-[500] text-white bg-text hover:bg-gray-800 w-full flex justify-center mt-[48px]"
                >
                  Try Professional
                </button>
                <div className="grid grid-cols-1 mt-[48px] gap-[8px]">
                  <h4 className="text-text font-[700] text-[20px]">
                    Everything in Essential plan +
                  </h4>

                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Talent needs consulation
                    </h4>
                  </div>
                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Compliance consultation
                    </h4>
                  </div>
                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Payroll services
                    </h4>
                  </div>
                  <div className="flex  space-x-[10px]">
                    <div>
                      <IoCheckmark className="text-[25px] " />
                    </div>
                    <h4 className="text-[18px] font-[400] text-[#101828]">
                      Recruitment performance report
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]   ">
        <div className="max-w-[1480px] mx-auto">
          <Faqs />
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:py-[50px] py-[40px] ">
        <div className="max-w-[1480px] mx-auto">
          <div className=" lg:flex lg:space-x-[30px] ">
            <div className="2xl:w-[35%] lg:w-[50%] rounded-[24px] px-[24px] pt-[50px] bg-[#FAFAFA] lg:h-[687px] flex flex-col">
              <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                Seamlessly Scale Your Tech Team With Vetted Talents 
              </h4>
              <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                Grow your tech team conveniently with vetted talents that align
                with your organizational culture and values.
              </h4>
              <button
                onClick={() =>
                  window.open("https://app.rivala.io/partners?step=1", "_blank")
                }
                className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px] w-[91px] flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px]"
              >
                Try now
              </button>
              <div className="mt-auto w-full pt-[20px]">
                <img className="mx-auto" src={p1} />
              </div>
            </div>
            <div className="2xl:w-[65%] lg:w-[50%] rounded-[24px] px-[24px] pt-[50px] bg-[#FAFAFA] lg:h-[687px] mt-[30px] lg:mt-[0px] flex flex-col">
              <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                Cut Down On Hiring Time
              </h4>
              <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                Get a curated list of qualified candidates within 72 hours and
                reduce your hiring time by over 40%.
              </h4>
              <button
                onClick={() =>
                  window.open("https://app.rivala.io/partners?step=1", "_blank")
                }
                className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px] w-[91px] flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px]"
              >
                Try now
              </button>
              <div className="mt-auto w-full pt-[20px]">
                <img className="mx-auto" src={p2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
