import React, { useState } from 'react';
import { FiChevronDown } from "react-icons/fi";
import search from "../assets/r-search.svg";
import background from "../assets/blog-bg.png";
import profile from "../assets/blog-profile.png";
import arrow from "../assets/blog-arrow.svg";

const products = [
  { id: 1, name: "Wireless Headphones", category: "Category 3" },
  { id: 2, name: "Smart Watch", category: "Category 1" },
  { id: 3, name: "Eco Bottle", category: "Category 2" },
  { id: 4, name: "Yoga Mat", category: "Category 4" },
  { id: 5, name: "Bluetooth Speaker", category: "Category 1" },
  { id: 6, name: "Coffee Maker", category: "Category 2" },
  { id: 7, name: "Air Fryer", category: "Category 4" },
  { id: 8, name: "Electric Kettle", category: "Category 1" },
  { id: 9, name: "Gaming Mouse", category: "Category 3" },
  { id: 10, name: "Laptop Stand", category: "Category 2" },
  { id: 11, name: "Portable Charger", category: "Category 4" },
  { id: 12, name: "Fitness Tracker", category: "Category 3" },
  { id: 13, name: "Instant Pot", category: "Category 1" },
  { id: 14, name: "Vacuum Cleaner", category: "Category 2" },
  { id: 15, name: "Smart Thermostat", category: "Category 3" },
  { id: 16, name: "Digital Photo Frame", category: "Category 4" },
  { id: 17, name: "Drone Camera", category: "Category 1" },
  { id: 18, name: "Electric Toothbrush", category: "Category 2" },
  { id: 19, name: "Tablet Holder", category: "Category 4" },
  { id: 20, name: "Massage Gun", category: "Category 3" },
  { id: 21, name: "Smart Lamp", category: "Category 1" },
  { id: 22, name: "LED Monitor", category: "Category 2" },
  { id: 23, name: "Noise Cancelling Headphones", category: "Category 4" },
  { id: 24, name: "Smart Doorbell", category: "Category 3" },
  { id: 25, name: "Home Security Camera", category: "Category 1" },
];

const Blog = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const [searchQuery, setSearchQuery] = useState('');

    const categories = ['All', ...new Set(products.map(product => product.category))];

    const filteredProducts = selectedCategory === 'All'
      ? products
      : products.filter(product => product.category === selectedCategory);

    const [isOpen, setIsOpen] = useState(false);
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setIsOpen(false);
    };

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    const currentProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const [isSearchActive, setIsSearchActive] = useState(false);

    return (
        <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:py-[50px] py-[40px] ">
            <div className="max-w-[1480px] mx-auto grid gap-10">
                <div className={`items-center flex gap-2 ${selectedCategory === 'All' ? 'hidden' : ''}`}>
                    <h5 
                        className="font-semibold text-sm leading-5 text-left text-[#101828] cursor-pointer"
                        onClick={() => setSelectedCategory('All')}
                    >
                        Blogs
                    </h5>
                    <img src={arrow} alt="Arrow" />
                    <h5 className="font-semibold text-sm leading-5 text-left text-[#9EA0A3]">{selectedCategory}</h5>
                </div>

            <div className={`flex justify-between items-center ${selectedCategory !== 'All' ? 'hidden' : ''} `}>
                    <h2 className="text-[#FF6611] capitalize text-4xl font-bold leading-[60px] tracking-[-0.02em] text-left">blog</h2>
                    <div className="flex w-fit h-fit gap-4">
                        <div className="relative">
                            {isSearchActive ? (
                                <input
                                    type="text"
                                    className="transition-all duration-300 ease-in-out border border-[#EAECF0] rounded-full p-[10px] w-[240px] outline-none focus:w-[240px] focus:outline-none"
                                    placeholder="Search"
                                    onBlur={() => setIsSearchActive(false)}
                                    autoFocus
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            ) : (
                                <button
                                    className="rounded-full p-[10px] border border-[#EAECF0] hover:bg-gray-100 transition-all duration-300 ease-in-out"
                                    onClick={() => setIsSearchActive(true)}
                                >
                                        <img src={search} alt="Search" />  
                                </button>
                            )}
                        </div>
                        <div className="relative inline-block w-[200px] my-auto">
                            <button
                              className="flex items-center justify-between w-full border border-[#EAECF0] hover:bg-gray-100 rounded-lg py-[10px] px-[14px] text-gray-700 leading-tight"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              {selectedCategory} <FiChevronDown />
                            </button>

                            {isOpen && (
                                <ul 
                                    className="absolute z-10 w-full mt-1 bg-white border border-[#EAECF0] rounded-lg shadow-lg"
                                    onMouseLeave={() => setIsOpen(false)}
                                >
                                    {categories.map((category) => (
                                        <li
                                            key={category}
                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => {
                                                handleCategorySelect(category);
                                                setCurrentPage(1);
                                            }}
                                        >
                                        {category}
                                      </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

                <h2
                    className={`text-[#FF6611] capitalize text-4xl font-bold leading-[60px] tracking-[-0.02em] text-left ${selectedCategory === 'All' ? 'hidden' : ''}`}
                >
                    {selectedCategory}
                </h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {currentProducts.map((product) => (
                        <div 
                            className="grid gap-6"
                            key={product.id}
                        >
                            <img className="w-full h-[256px] rounded-[8px] mx-auto" src={background} alt="Background" />
                            <div className="w-full grid gap-3">
                                <h3 className="text-[#FF6611] capitalize font-semibold text-base leading-6 text-left">{product.category}</h3>
                                <h2 className="font-bold text-xl leading-8 text-left text-[#000000] line-clamp-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</h2>
                                <p className="font-medium text-base leading-6 text-left text-[#9EA0A3] line-clamp-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <div className="flex gap-1 w-full">
                                    <img className="w-10 h-10 rounded-full border my-auto" src={profile} alt="Profile" />
                                    <div>
                                        <h6 className="capitalize font-medium text-base leading-6 text-left text-[#161618]">{product.name}</h6>
                                        <h4 className="capitalize font-normal text-sm leading-5 text-left text-[#9EA0A3]">26 December 2024</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                <div className={`flex items-center mx-auto w-fit ${totalPages < 2 ? 'hidden' : ''}`}>
                    <button 
                        className="w-[32px] h-[32px] p-2 gap-2 rounded-md flex items-start hover:bg-gray-100"
                        onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} 
                    >
                        <FiChevronDown className="transform rotate-[90deg]" />
                    </button>
                    
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            className={`w-[32px] h-[32px] p-2 gap-2 rounded-md justify-center flex items-center hover:bg-gray-100 ${currentPage === index + 1 ? 'bg-[#04040A] text-white' : ''}`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    
                    <button 
                        className="w-[32px] h-[32px] p-2 gap-2 rounded-md flex items-start hover:bg-gray-100"
                        onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} 
                    >
                        <FiChevronDown className="transform rotate-[270deg]" />
                    </button>

                </div>
            </div>
        </div>
    );
};

export default Blog;
