import { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ht1 from "../assets/ht1.png";
import ht2 from "../assets/ht2.png";
import ht3 from "../assets/ht3.png";

const Testimonial = () => {
  const slides = [
    <div key={1} className="md:flex md:gap-[25px] md:mr-[24px]">
      <div className="w-[33%] hidden md:block">
        <img src={ht1} />
      </div>
      <div className="bg-text md:w-[66%] w-[100%] flex flex-col p-[24px] rounded-[16px] h-[350px] md:h-auto">
        <h4 className="text-[#F2F2F2] font-[700] text-[24px] leading-[32px]">
          Hiring time saved
        </h4>
        <h4 className="text-[16px] font-[500] leading-[24px] text-[#F2F2F2] mt-[24px]">
          Rivala's team helped us set up a fully remote dev team in just two
          weeks for a project saving us a lot of time to fill the roles.
        </h4>
        <div className="mt-auto md:flex justify-between items-center ">
          <h4 className="text-[#F2F2F2] text-[20px] font-[600]">David L</h4>
          <h4 className="text-[#F2F2F2] text-[16px] font-[500]">
            CTO at Cloud Technology Services Inc.
          </h4>
        </div>
      </div>
    </div>,
    <div key={2} className="md:flex md:gap-[25px] md:mr-[24px]">
      <div className="w-[33%] hidden md:block">
        <img src={ht3} />
      </div>
      <div className="bg-[#FABC1C] md:w-[66%] w-[100%] flex flex-col p-[24px] rounded-[16px] h-[350px] md:h-auto">
        <h4 className="text-text font-[700] text-[24px] leading-[32px]">
          Exceptional candidates delivered
        </h4>
        <h4 className="text-[16px] font-[500] leading-[24px] text-text mt-[24px]">
          We were struggling to find the right data engineer until we partnered
          with Rivala. Their team delivered exceptional candidates who fit into
          our company culture and brought in the needed expertise.
        </h4>
        <div className="mt-auto md:flex justify-between items-center ">
          <h4 className="text-text text-[20px] font-[600]">Kate B</h4>
          <h4 className="text-text text-[16px] font-[500]">
            Talent Acquisition Manager at Levered
          </h4>
        </div>
      </div>
    </div>,
    <div key={3} className="md:flex md:gap-[25px] md:mr-[24px]">
      <div className="w-[33%] hidden md:block">
        <img src={ht2} />
      </div>
      <div className="bg-[#6EC1FC] md:w-[66%] w-[100%] flex flex-col p-[24px] rounded-[16px] h-[350px] md:h-auto">
        <h4 className="text-text font-[700] text-[24px] leading-[32px]">
          Developers hired
        </h4>
        <h4 className="text-[16px] font-[500] leading-[24px] text-text mt-[24px]">
          We hired three developers from Rivala during our last round of
          funding. They are highly skilled and have greatly contributed to our
          team.
        </h4>
        <div className="mt-auto md:flex justify-between items-center">
          <h4 className="text-text text-[20px] font-[600]">Idris A </h4>
          <h4 className="text-text text-[16px] font-[500]">
            Co-founder at Tappr
          </h4>
        </div>
      </div>
    </div>,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };
  return (
    <div className="max-w-[1480px] mx-auto relative">
      <div className="md:flex justify-between items-end ">
        <div>
          <h4 className="text-text md:font-[700] font-[600] md:leading-[60px] tracking-tighter md:text-[48px] text-[38px]">
            Hear from our partners
          </h4>
          <h4 className="text-text font-[500] leading-[28px]  text-[18px] md:w-[600px] xl:w-[698px] mt-[10px]">
            Discover the impact of Rivala through our clients' testimonials.
            Each story highlights our dedication to finding exceptional
            candidates who perfectly fit their company’s culture and needs.
          </h4>
        </div>
        <div className="hidden lg:block">
          <div className="flex gap-[12px]">
            <button
              className="flex justify-center items-center rounded-full border-[1px] border-[#C0C2C4] w-[44px] h-[44px]"
              onClick={goToPreviousSlide}
            >
              <FiChevronLeft className="text-[30px] stroke-1 text-text" />
            </button>
            <button
              className="flex justify-center items-center rounded-full border-[1px] border-[#C0C2C4] w-[44px] h-[44px]"
              onClick={goToNextSlide}
            >
              <FiChevronRight className="text-[30px] stroke-1 text-text" />
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-hidden mt-[48px]">
        <div
          className="flex transition-transform duration-300 ease-in-out 2xl:w-[50.5%] xl:w-[70%] md:w-[90%] lg:w-[70%] sm:w-[100%] w-[100%] "
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className=" min-w-[100%]">
              <div>{slide}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="lg:hidden mt-[30px]">
        <div className="flex justify-between  ">
          <button
            className="flex justify-center items-center rounded-full border-[1px] border-[#C0C2C4] w-[44px] h-[44px]"
            onClick={goToPreviousSlide}
          >
            <FiChevronLeft className="text-[30px] stroke-1 text-text" />
          </button>
          <button
            className="flex justify-center items-center rounded-full border-[1px] border-[#C0C2C4] w-[44px] h-[44px]"
            onClick={goToNextSlide}
          >
            {" "}
            <FiChevronRight className="text-[30px] stroke-1 text-text" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
