import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { getQueryString } from "../utils/helpers";

const LIMIT = 50;
const BASE_URL = "https://rivala-2302031d57e0.herokuapp.com/api/v1";
// const BASE_URL = "https://rivala-backend-staging-45450be302f6.herokuapp.com/api/v1"

const useJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [offset, setOffset] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isFilteredOrSearched, setIsFilteredOrSearched] = useState(false);
  const [currentParams, setCurrentParams] = useState({});

  const fetchJobs = useCallback(async (params = {}) => {
    setIsLoading(true);

    try {
      let response;

      response = await axios.get(`${BASE_URL}/job/filter/68`, {
        params: {
          ...(params.filters ? getQueryString(params.filters) : {}),
          ...(params.search ? { queryString: params.search } : {}),
          offset,
          limit: LIMIT,
        },
      });

      setJobs(response.data.data);
      setHasMore(response.data.pagination.hasNextPage);
      setOffset(2);

      setIsFilteredOrSearched(!!params.filters || !!params.search);
      setCurrentParams(params);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setIsFilteredOrSearched(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchMoreJobs = useCallback(async () => {
    if (isLoading || isFetchingMore || !hasMore) return;

    setIsFetchingMore(true);

    try {
      const response = await axios.get(`${BASE_URL}/job/filter/68`, {
        params: {
          ...(currentParams.filters
            ? getQueryString(currentParams.filters)
            : {}),
          ...(currentParams.search
            ? { queryString: currentParams.search }
            : {}),
          offset,
          limit: LIMIT,
        },
      });

      setJobs((prevJobs) => {
        const allJobs = [...prevJobs, ...response.data.data];
        return _.uniqBy(allJobs, "id");
      });
      setHasMore(response.data.pagination.hasNextPage);
      setOffset((prev) => prev + 1);
    } catch (err) {
      console.error("Error fetching more jobs:", err);
    } finally {
      setIsFetchingMore(false);
    }
  }, [isLoading, isFetchingMore, hasMore, offset]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 250 >=
        document.documentElement.scrollHeight
      ) {
        fetchMoreJobs();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return {
    jobs,
    isLoading,
    fetchJobs,
    isFilteredOrSearched,
    isFetchingMore,
  };
};

export default useJobs;
