export function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace("_", "-");
}

export function getQueryString(filters) {
  const queryParams = {};

  Object.keys(filters).forEach((key) => {
    const values = filters[key];
    if (values.length > 0) {
      queryParams[key] = values.join(",");
    }
  });

  return queryParams;
}

export function convertPathToTitle(path) {
  return path
    .replace(/^\//, "")
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}
