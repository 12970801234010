import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FiSearch, FiChevronDown, FiChevronRight, FiX } from "react-icons/fi";
import {
  datePostedOptions,
  employmentTypeOptions,
  jobTypeOptions,
  locationOptions,
} from "../utils/data";
import useJobs from "../hooks/use-jobs";
import { capitalizeWords } from "../utils/helpers";

const initialFiltersState = { datePosted: [], employmentType: [], jobType: [] };

const Jobs = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersState);

  const { jobs, isLoading, fetchJobs, isFilteredOrSearched, isFetchingMore } =
    useJobs();

  const params = {
    filters: selectedFilters,
    search: searchValue,
  };

  const jobsSectionRef = useRef(null);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleFilterChange = (category, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]:
        category === "datePosted"
          ? prevFilters[category].includes(value)
            ? []
            : [value]
          : prevFilters[category].includes(value)
          ? prevFilters[category].filter((item) => item !== value)
          : [...prevFilters[category], value],
    }));
  };

  const handleApplyFilters = () => {
    fetchJobs(params);
    setShowFilters(false);

    jobsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJobSearch = (e) => {
    if (e.key === "Enter") fetchJobs(params);
  };

  const handleClearResults = () => {
    fetchJobs();
    setSearchValue("");
    setSelectedFilters(initialFiltersState);
  };

  return (
    <div>
      <div className="bg-[#04040A] mb-[52px] md:mb-[32px] px-4 md:px-6">
        <h1
          className="text-[35px] md:text-[48px] text-white max-w-[768px] text-center pt-[68px] pb-[35px] md:py-[68px] mx-auto font-semibold"
          ref={jobsSectionRef}
        >
          Explore Your Ultimate Career Opportunity.
        </h1>
      </div>
      <div className="flex flex-col md:flex-row items-start gap-[36px] lg:gap-[72px] max-w-[1120px] mx-auto mb-10 px-4 md:px-6">
        <div className="sticky top-3">
          <FilterPanel
            view="desktop"
            setShowFilters={setShowFilters}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onApplyFilters={handleApplyFilters}
          />
        </div>
        <main className="flex-1 w-full">
          <div className="flex gap-4">
            <div className="relative flex-1">
              <FiSearch
                className="absolute top-3 left-4 stroke-[1.5px]"
                size={20}
              />
              <input
                type="text"
                placeholder="Job title or skill"
                className="w-full p-3 pl-12 border border-gray-100 rounded-md text-sm focus:outline-black h-[44px] placeholder:font-light"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleJobSearch}
              />
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex justify-between items-center py-6">
                <h3 className="text-lg">{jobs ? jobs.length : 0} Job(s)</h3>
                {isFilteredOrSearched ? (
                  <p
                    className="text-sm text-orange-500 cursor-pointer"
                    onClick={handleClearResults}
                  >
                    Clear results
                  </p>
                ) : (
                  <p
                    className="block md:hidden flex text-gray-500 items-center border rounded-full border-gray-200 py-1 px-3 text-sm"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <FilterIcon />
                    Filter
                  </p>
                )}
              </div>
              {showFilters && !isFilteredOrSearched && (
                <FilterPanel
                  setShowFilters={setShowFilters}
                  selectedFilters={selectedFilters}
                  onFilterChange={handleFilterChange}
                  onApplyFilters={handleApplyFilters}
                />
              )}
              <div>
                {jobs && jobs.length > 0 ? (
                  jobs.map((job) => <JobCard key={job.id} job={job} />)
                ) : (
                  <div className="text-center" style={{ padding: "120px 0" }}>
                    No job postings available at the moment.
                  </div>
                )}
                {isFetchingMore && <Loader />}
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Jobs;

// Loader.js
const Loader = () => (
  <div className="text-center py-40">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

// FilterIcon.js
const FilterIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
    >
      <g clipPath="url(#clip0_1356_1982)">
        <path
          d="M2.6665 4H13.3332"
          stroke="#161618"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.3335 8H10.6668"
          stroke="#161618"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 12H12"
          stroke="#161618"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1356_1982">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

// FilterSection.js
const FilterSection = ({
  title,
  options,
  selectedOptions,
  onChange,
  singleSelect,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isOpen, selectedOptions]);

  return (
    <div>
      <h3
        className="px-4 py-3 font-medium text-sm border-b border-gray-100 flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? <FiChevronDown size={16} /> : <FiChevronRight size={16} />}
      </h3>
      <div
        ref={contentRef}
        className="transition-height duration-300 ease-in-out overflow-hidden"
        style={{ height }}
      >
        <div className="px-4">
          {options.map(({ name, value }) => (
            <div key={value} className={"custom-checkbox"}>
              <label className="block py-[10px] text-sm text-gray-500">
                <input
                  type={"checkbox"}
                  name={singleSelect ? title : value}
                  value={value}
                  checked={
                    singleSelect
                      ? selectedOptions[0] === value
                      : selectedOptions.includes(value)
                  }
                  onChange={() => onChange(value)}
                />
                <span></span>
                {name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// FilterPanel.js
const FilterPanel = ({
  view,
  setShowFilters,
  selectedFilters,
  onFilterChange,
  onApplyFilters,
}) => {
  const isSelectedFilters = Object.values(selectedFilters).some(
    (category) => category.length > 0
  );

  return (
    <div
      className={`w-full md:w-80 border rounded-lg border-gray-100 mb-8 ${
        view === "desktop" ? "hidden md:block" : "block"
      }`}
    >
      <p
        className="block text-gray-500 md:hidden px-4 py-3 border-b border-gray-100 flex justify-between items-center text-sm"
        onClick={() => setShowFilters(false)}
      >
        Filter <FiX size={16} />
      </p>
      <FilterSection
        singleSelect={true}
        title="Date posted"
        options={datePostedOptions}
        selectedOptions={selectedFilters.datePosted}
        onChange={(value) => onFilterChange("datePosted", value)}
      />
      <FilterSection
        title="Employment Type"
        options={employmentTypeOptions}
        selectedOptions={selectedFilters.employmentType}
        onChange={(value) => onFilterChange("employmentType", value)}
      />
      <FilterSection
        title="Job Type"
        options={jobTypeOptions}
        selectedOptions={selectedFilters.jobType}
        onChange={(value) => onFilterChange("jobType", value)}
      />
      <div className="p-4 mb-2">
        <button
          className={`bg-black text-white rounded-md h-[44px] w-full ${
            isSelectedFilters ? "bg-black" : "bg-gray-300"
          }`}
          onClick={onApplyFilters}
          disabled={!isSelectedFilters}
        >
          Show results
        </button>
      </div>
    </div>
  );
};

// JobCard.js
const JobCard = ({ job }) => {
  let jobLocation = "";

  if (job.location) {
    const locationValues = job.location.split(",").map((value) => value.trim());
    const locationLabels = locationValues
      .map((val) => locationOptions.find(({ value }) => value === val)?.label)
      .filter((value) => value);

    const otherCount = locationLabels.length - 1;

    jobLocation =
      otherCount > 0
        ? `${locationLabels[0]} +${otherCount} more`
        : locationLabels[0];
  }

  return (
    <div
      className="bg-white border border-gray-100 rounded-lg p-4 cursor-pointer relative mb-5 hover:transform hover:border-gray-200 transition-transform duration-200"
      onClick={() =>
        window.open(
          `https://app.venohr.io/careers/${job.id}`,
          // `https://venohr-staging.vercel.app/careers/${job.id}`,
          "_blank"
        )
      }
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <img
            src={job.companyLogo}
            alt="Company Logo"
            className="rounded-full w-[35px] h-[35px]"
          />
          <div className="ml-4 mb-1">
            <h2 className="font-semibold text-sm pb-1">{job.companyName}</h2>
            <p className="text-xs text-secondary font-light">{jobLocation}</p>
          </div>
        </div>
        <p className="text-xs text-gray-400">
          {moment(job.createdAt).fromNow()}
        </p>
      </div>
      <div className="mt-2">
        <h3 className="mb-2">{job.title}</h3>
        <p className="text-xs text-gray-400 flex items-center">
          {capitalizeWords(job.type)}
          <span className="bg-gray-500 w-[2px] h-[2px] rounded-lg mx-1"></span>
          {capitalizeWords(job.employmentType)}
        </p>
      </div>
    </div>
  );
};
