const policy = [
  {
    slides: [
      {
        sections: [
          {
            title: "Introduction",
            number: "1",
            texts: [
              "1.1 Overview",
              "Welcome to Rivala, a marketplace for technologists. This Privacy Policy outlines how we collect, use, and protect your information when you use our website, web application, and related services (collectively, the “Services”). By accessing or using our Services, you agree to the terms of this Privacy Policy.",
              "1.2 Acceptance of Policy",
              "By using our Services, you acknowledge that you have read and understood this Privacy Policy. If you do not agree with the terms outlined herein, please refrain from using our Services.",
            ],
          },
          {
            title: "Information We Collect",
            number: "2",
            texts: [
              "2.1 Personal Information",
              "We collect various types of personal information, including but not limited to your name, email address, contact information, and other identifiers when you register for an account, submit forms, or interact with our Services.",
              "2.2 Usage Information",
              "We gather information about how you use our Services, including your IP address, device information, browser type, and pages visited. This helps us analyze trends, improve functionality, and enhance user experience.",
              "2.3 Cookies and Tracking Technologies",
              "We use cookies and similar technologies to enhance your experience, customize content, and analyze trends. You can manage cookie preferences through your browser settings.",
              "2.4 Third-Party Data",
              "We may receive information from third-party sources to enhance the accuracy of our records and improve our Services.",
            ],
          },
          {
            title: "How We Use Your Information",
            number: "3",
            texts: [
              "3.1 Providing and Improving Services",
              "We use the information we collect for various purposes, including providing and improving our Services, customizing content and user experience, communicating with you about updates and promotions, and analyzing usage patterns and trends.",
              "3.2 Customization and Personalization",
              "We strive to provide a personalized experience by using your information to tailor our Services to your preferences and interests.",
              "3.3 Communication",
              "We may use your contact information to communicate with you about our Services, promotions, and important updates. You can manage your communication preferences through your account settings.",
              "3.4 Analytics and Insights",
              "We analyze user behaviour and trends to gain insights into how our Services are used. This helps us enhance functionality and optimize user experience.",
            ],
          },
          {
            title: "Information Sharing",
            number: "4",
            texts: [
              "4.1 With Your Consent",
              "We do not sell, trade, or otherwise transfer your personal information to third parties without your consent.",
              "4.2 Service Providers",
              "We may share information with trusted third-party service providers who assist us in operating our Services, conducting our business, or servicing you.",
              "4.3 Legal Requirements",
              "We may disclose your information if required by law, governmental request, or where we believe it is necessary to protect our legal rights, the rights of others, or for the prevention or detection of a crime.",
              "4.4 Business Transfers",
              "In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.",
            ],
          },
          {
            title: "Your Choices",
            number: "5",
            texts: [
              "5.1 Account Settings",
              "You can manage your account settings, update preferences, and adjust privacy settings to control the information you share.",
              "5.2 Communication Preferences",
              "While we may send essential communications related to your account, you can opt out of promotional communications.",
              "5.3 Cookie Management",
              "You can manage cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our Services.",
              "5.4 Data Access and Deletion Requests",
              "You have the right to access the information we hold about you and request its deletion. Please contact us at writeus@rivala.io for assistance.",
            ],
          },
          {
            title: "Data Security",
            number: "6",
            texts: [
              "6.1 Security Measures",
              "We employ industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure.",
              "6.2 Data Retention",
              "We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law.",
              "6.3 Third-Party Security",
              "While we take measures to protect your data, we cannot guarantee the security of information transmitted to or from our Services over the Internet.",
            ],
          },
          {
            title: "Children’s Privacy",
            number: "7",
            texts: [
              "7.1 Age Restriction",
              "Our Services are not intended for users under the age of 18. We do not knowingly collect or store information from children.",
              "7.2 Parental Controls",
              "Parents or legal guardians can contact us at writeus@rivala.io to inquire about and manage the information collected.",
            ],
          },
          {
            title: "International Users",
            number: "8",
            texts: [
              "8.1 Cross-Border Data Transfer",
              "By using our Services, you consent to the transfer of your information to countries outside your country of residence, including the United States, where data protection laws may differ.",
              "8.2 Compliance with Laws",
              "We comply with applicable data protection laws and regulations, and we expect our users to do the same.",
            ],
          },
          {
            title: "Changes to this Privacy Policy",
            number: "9",
            texts: [
              "9.1 Notification of Changes",
              "We may update this Privacy Policy to reflect changes in our practices or legal requirements. Significant changes will be communicated through our website or by other means.",
              "9.2 Effective Date",
              "The date of the latest revision will be indicated at the top of the policy.",
            ],
          },
          {
            title: "Contact Us",
            number: "10",
            texts: [
              "10.1 General Inquiries",
              "For general inquiries regarding this Privacy Policy, please contact us at writeus@rivala.io.",
              "10.2 Data Protection Officer",
              "If you have specific concerns about data protection, you can contact our Data Protection Officer at writeus@rivala.io.",
            ],
          },
        ],
      },
    ],
  },
];

const terms = [
  {
    slides: [
      {
        sections: [
          {
            title: "Introduction",
            number: "1",
            texts: [
              "1.1 Agreement to Terms",
              'By accessing or using any of the services offered by Rivala ("Services"), you acknowledge that you have read, understood, and agree to be bound by the following Terms of Service ("Terms"). If you do not agree to these Terms, you are not authorized to use the Services.',
              "1.2 Eligibility",
              "You must be at least 18 years of age and have the legal capacity to enter into a binding agreement to use the Services. You may not use the Services if you are prohibited from doing so under applicable laws or regulations.",
            ],
          },
          {
            title: "Account Creation and Use",
            number: "2",
            texts: [
              "2.1 Creating an Account",
              "To access certain features of the Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and are responsible for all activities that occur under your account. You agree to notify Rivala immediately of any unauthorized use of your account or any other breach of security.",
            ],
          },
          {
            title: "Acceptable Use",
            number: "2.2",
            texts: [
              "",
              "You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the Services:",
            ],
            list: [
              "In any way that violates any applicable laws or regulations.",
              "To harm or exploit children.",
              "To impersonate any person or entity.",
              "To interfere with or disrupt the Services or any servers or networks connected to the Services.",
              "To upload or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.",
              "To violate the intellectual property rights of others.",
              "To attempt to gain unauthorized access to the Services or any related systems or networks.",
              "To use any automated means, such as bots, to access the Services.",
            ],
          },
          {
            title: "Content and User Conduct",
            number: "2.3",
            texts: [
              "",
              'You are solely responsible for any content you upload, transmit, or share on the Services ("User Content"). You agree that your User Content will comply with these Terms and all applicable laws and regulations. You grant Rivala a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display your User Content on the Services and for any other purposes related to the Services.',
            ],
          },
          {
            title: "Intellectual Property",
            number: "2.4",
            texts: [
              "",
              "The Services and all content and materials on the Services, including but not limited to text, graphics, logos, images, audio, video, software, and trademarks, are protected by intellectual property rights owned or controlled by Rivala or its licensors. You agree not to use any of these materials for any unauthorized purpose.",
            ],
          },
          {
            title: "Fees and Payment",
            number: "3",
            texts: [
              "",
              "If any fees are associated with the Services, you will be notified of the applicable fees and payment terms before you are required to pay. You agree to pay all applicable fees in accordance with the payment terms.",
            ],
          },
          {
            title: "Refunds and Cancellations",
            number: "3.1",
            texts: [
              "",
              "(Optional: Include information about refunds and cancellations if applicable)",
            ],
          },
          {
            title: "Disclaimers and Warranties",
            number: "4",
            texts: [
              "",
              'THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. RIVALA DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE.',
            ],
          },
          {
            title: "Disclaimer of Warranties",
            number: "4.1",
            texts: [
              "",
              "YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. RIVALA DOES NOT WARRANT THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. RIVALA DOES NOT WARRANT THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE.",
            ],
          },
          {
            title: "Limitation of Liability",
            number: "4.2",
            texts: [
              "",
              "TO THE FULLEST EXTENT PERMITTED BY LAW, RIVALA SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, AND PUNITIVE DAMAGES, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF RIVALA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
            ],
          },
          {
            title: "Term and Termination",
            number: "5",
            texts: [
              "",
              "These Terms are effective upon your first access to the Services and will remain in effect until terminated by you or Rivala. You may terminate these Terms by discontinuing your use of the Services. Rivala may terminate these Terms at any time and for any reason, with or without notice.",
            ],
          },
          {
            title: "Term",
            number: "5.1",
            texts: [
              "",
              "These Terms will be effective for an indefinite duration, starting from your first access to the Services unless terminated earlier in accordance with the provisions outlined herein.",
            ],
          },
          {
            title: "Termination",
            number: "5.2",
            texts: [
              "",
              "You may terminate these Terms by ceasing to use the Services and closing your account, if applicable. Rivala reserves the right to terminate these Terms, suspend, or restrict your access to the Services at any time and for any reason, without prior notice or liability to you. Upon termination, your rights to use the Services will cease immediately, and you must cease all use of the Services.",
            ],
          },
          {
            title: "Governing Law and Dispute Resolution",
            number: "6",
            texts: [
              "6.1 Governing Law",
              "These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, United States of America, Canada, Germany, Estonia, Netherlands, Nigeria, Kenya, Egypt, South Africa, and Portugal, without regard to its conflict of law provisions.",
            ],
          },
          {
            title: "Dispute Resolution",
            number: "6.2",
            texts: [
              "",
              "Any dispute arising out of or relating to these Terms or the Services shall be resolved through binding arbitration administered by [Arbitration Organization] in accordance with its rules and procedures. The arbitration shall take place in [Location], and the decision of the arbitrator shall be final and binding on the parties. Each party shall bear its own costs and expenses associated with the arbitration.",
            ],
          },
          {
            title: "Entire Agreement and Severability",
            number: "7",
            texts: [
              "7.1 Entire Agreement",
              "These Terms constitute the entire agreement between you and Rivala regarding the subject matter herein and supersede all prior or contemporaneous agreements, representations, warranties, and understandings, whether written or oral, relating to such subject matter.",
            ],
          },
          {
            title: "Severability",
            number: "7.2",
            texts: [
              "",
              "If any provision of these Terms is held to be invalid, illegal, or unenforceable under any applicable law, such provision shall be deemed modified to the extent necessary to make it valid, legal, and enforceable while preserving its intent. If such modification is not possible, the provision shall be deemed severed from these Terms, and the remaining provisions shall continue in full force and effect. By accessing or using the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.",
            ],
          },
        ],
      },
    ],
  },
];

const datePostedOptions = [
  { name: "Last 24 hours", value: "last_24_hours" },
  { name: "Last 3 days", value: "last_3_days" },
  { name: "Last 7 days", value: "last_7_days" },
  { name: "Last 14 days", value: "last_14_days" },
  { name: "Last 30 days", value: "last_30_days" },
];
const employmentTypeOptions = [
  { name: "Full-time", value: "full_time" },
  { name: "Part-time", value: "part_time" },
  { name: "Contract", value: "contract" },
  { name: "Freelance", value: "freelance" },
  { name: "Internship", value: "internship" },
];
const jobTypeOptions = [
  { name: "On Site", value: "on-site" },
  { name: "Hybrid", value: "hybrid" },
  { name: "Remote", value: "remote" },
];

const locationOptions = [
  { value: "EMEA", label: "EMEA" },
  { value: "AMER", label: "AMER" },
  { value: "LATAM", label: "LATAM" },
  { value: "NA", label: "NA" },
  { value: "APJ", label: "APJ" },
  { value: "EU", label: "EU" },
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CV", label: "Cabo Verde" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CD", label: "Congo, Democratic Republic of the" },
  { value: "CG", label: "Congo, Republic of the" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "TL", label: "East Timor (Timor-Leste)" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "SZ", label: "Eswatini (Swaziland)" },
  { value: "ET", label: "Ethiopia" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GR", label: "Greece" },
  { value: "GD", label: "Grenada" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HN", label: "Honduras" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "CI", label: "Ivory Coast (Côte d'Ivoire)" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, North" },
  { value: "KR", label: "Korea, South" },
  { value: "XK", label: "Kosovo" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "MK", label: "North Macedonia" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "São Tomé and Príncipe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
];

export {
  terms,
  policy,
  datePostedOptions,
  employmentTypeOptions,
  jobTypeOptions,
  locationOptions,
};
