import React from "react";
import { policy } from "../utils/data";

const Policy = () => {
  return (
    <div>
      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:pt-[100px] pt-[80px] bg-white">
        <div className="max-w-[1480px] mx-auto">
          <div className="flex flex-col justify-center items-center space-y-[24px]  md:pb-[100px] pb-[60px]">
            <h4 className="lg:text-[60px] text-[36px] font-[600] md:font-[700] leading-[44px] lg:leading-[72px] text-text text-center tracking-tight">
              Privacy Policy
            </h4>
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%] xl:py-[100px] py-[80px] bg-[#F2F2F2]">
        <div className="max-w-[845px] mx-auto">
          {policy.map((policy, index) => (
            <div key={index}>
              <div>
                {policy.slides.map((slide, slideIndex) => (
                  <div key={slideIndex}>
                    {slide.sections.map((section, sectionIndex) => (
                      <div className="" key={sectionIndex}>
                        <div className="flex">
                          <div className="flex justify-end mr-[5px]">
                            <h4 className="text-[16px] font-[700] text-text leading-[24px]">
                              {section.number}.
                            </h4>
                          </div>
                          <h3 className="text-[16px] font-[700] text-text leading-[24px]">
                            {section.title}
                          </h3>
                        </div>
                        <div className="w-full ">
                          {section.texts.map((text, textIndex) => (
                            <div
                              className={`mt-[10px] text-text ${
                                textIndex % 2 === 0
                                  ? "font-[700] text-[16px]"
                                  : "font-[500] text-[16px] mb-[50px]"
                              } leading-[24px]`}
                              key={textIndex}
                            >
                              <h4>{text}</h4>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <h4 className="text-[16px] font-[500] text-black">
            Latest update:{" "}
            <span className="text-[#FF6611]">January 19th 2024.</span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Policy;
