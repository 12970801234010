import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import PageRender from "./customRouter/PageRender";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import PartnerSignup from "./pages/PartnerSignup";
import { convertPathToTitle } from "./utils/helpers";

ReactGA.initialize("G-P126T719VP");

const Main = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: convertPathToTitle(location.pathname),
    });
  }, [location]);

  return (
    <div className="font-manrope">
      <ScrollToTop />
      {location.pathname !== "/partner-signup" && (
        <Navbar show={show} setShow={setShow} />
      )}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/:page" element={<PageRender />} />
        <Route exact path="/partner-signup" element={<PartnerSignup />} />
      </Routes>
      <Footer />
    </div>
  );
};

const App = () => (
  <Router>
    <Main />
  </Router>
);

export default App;
